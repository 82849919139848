import OneSignal from 'react-onesignal';
import { Store } from './services/store';

export default async function runOneSignal() {
    await OneSignal.init({
      appId: '4ed76af5-066a-4432-bf40-e4e985861403', // one signal Prod
      //  appId: '6499f120-48b8-4310-8d6d-21d36c180e79', // OneSignal WebTest
        // safari_web_id: "",
        notifyButton: {
          enable: true,
        },
        allowLocalhostAsSecureOrigin: true
    });
    window.OneSignal = window.OneSignal || [];
}
